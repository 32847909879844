import { InputHTMLAttributes } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { clsxMerge } from "shared/lib/helpers";

type InputPropsI = InputHTMLAttributes<HTMLInputElement>;

export interface TextFieldPropsI extends PropsWithClassNameI {
  name?: string;
  label?: string;
  labelClassName?: string;
  placeholder: string;
  errors?: string;
  touched?: boolean;
  disabled?: boolean;
  inputProps?: InputPropsI;
  inputClassName?: string;
}

const DefaultInput = (props: InputPropsI) => <input {...props} />;

const TextField =
  (Field = DefaultInput) =>
  // eslint-disable-next-line react/display-name
  ({
    name,
    label,
    labelClassName,
    placeholder,
    errors,
    touched,
    className,
    disabled,
    inputProps = {},
    inputClassName = "",
  }: TextFieldPropsI) => {
    return (
      <div className={clsxMerge("form-control mb-3 w-full", className)}>
        {!!label && (
          <div className="mb-2">
            <label
              className={clsxMerge("text-base-content/60", labelClassName, {
                "ae-typography-detail1":
                  !labelClassName?.includes("typography"),
              })}
            >
              {label}
            </label>
          </div>
        )}

        <Field
          name={name}
          type="text"
          placeholder={placeholder}
          disabled={disabled}
          className={clsxMerge(
            "input ae-typography-body1 grow",
            "bg-black/5 focus:bg-black/10 disabled:text-black/60",
            errors && touched && "border-error-content",
            inputClassName
          )}
          {...inputProps}
        />

        {errors && touched && (
          <div className="mt-1 flex items-center text-xs ">
            <ExclamationCircleIcon className="text-error-content bg-neutral-content mr-1 h-5 w-5" />
            <div className="text-error-content">{errors}</div>
          </div>
        )}
      </div>
    );
  };

export default TextField;
