import {
  QueryClient,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { produce } from "immer";
import { AxiosResponse } from "axios";

import { useApiClient } from "@/context/api-client";
import { NOTIFICATIONS_TABS } from "shared/ui/notifications/panel/tabs";
import { LocalNotificationsQueryKeys } from "@/modules/notifications/queries/keys";
import { GetNotificationsResponseI } from "@/api/routes/notifications";
import { useGlobalNotificationsContext } from "@/context/notifications-context";

// Marking all notifications as read using queryClient.setQueryData
const markAllNotificationsAsRead = (queryClient: QueryClient) => {
  Object.values(NOTIFICATIONS_TABS).forEach((section) => {
    const queryKey = LocalNotificationsQueryKeys.notifications(section);

    if (!queryClient.getQueryData(queryKey)) {
      return;
    }

    queryClient.setQueryData(
      LocalNotificationsQueryKeys.notifications(section),
      (data: { pages: AxiosResponse<GetNotificationsResponseI>[] }) =>
        produce(data, (draftData) =>
          draftData.pages.forEach((page) =>
            page.data.notifications.forEach((notification) => {
              notification.is_seen = true;
            })
          )
        )
    );
  });
};

const markNotificationAsRead = (
  queryClient: QueryClient,
  notificationId: string
) => {
  Object.values(NOTIFICATIONS_TABS).forEach((section) => {
    const queryKey = LocalNotificationsQueryKeys.notifications(section);

    if (!queryClient.getQueryData(queryKey)) {
      return;
    }

    queryClient.setQueryData(
      LocalNotificationsQueryKeys.notifications(section),
      (data: { pages: AxiosResponse<GetNotificationsResponseI>[] }) =>
        produce(data, (draftData) =>
          draftData.pages.forEach((page) =>
            page.data.notifications.forEach((notification) => {
              if (notificationId === notification.id) {
                notification.is_seen = true;
              }
            })
          )
        )
    );
  });
};

export const useReadAllNotificationsMutation = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const { reload: reloadNotificationsCounter } =
    useGlobalNotificationsContext();

  return useMutation({
    mutationFn: () => apiClient.notifications.markReadAllNotifications(),
    onSuccess: () => {
      markAllNotificationsAsRead(queryClient);
      reloadNotificationsCounter?.();
    },
  });
};

export const useReadNotificationMutation = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const { reload: reloadNotificationsCounter } =
    useGlobalNotificationsContext();

  return useMutation({
    mutationFn: (notificationId: string) =>
      apiClient.notifications.markReadNotification(notificationId),
    onSuccess: (_, notificationId) => {
      markNotificationAsRead(queryClient, notificationId);
      reloadNotificationsCounter?.();
    },
  });
};
