import _mapValues from "lodash/mapValues";

export const LOGO_VARIATION = {
  DEFAULT: "default",
  LIGHT: "default",
  DARK: "dark",
  WHITE: "white",
  BLACK: "black",
  SMALL_WHITE: "small_white",
  GLENX: "glenx",
  GLENX_DARK: "glenx_dark",
} as const;

const BASE_ASSET_PATH = "https://glencoco-assets.s3.amazonaws.com/logos/";

export const LOGO_VARIATION_MAP = _mapValues(
  {
    [LOGO_VARIATION.DEFAULT]: "v2/logo-glencoco-v2-default.svg",
    [LOGO_VARIATION.WHITE]: "v2/logo-glencoco-v2-mono-white.svg",
    [LOGO_VARIATION.BLACK]: "v2/logo-glencoco-v2-mono-black.svg",
    [LOGO_VARIATION.DARK]: "v2/logo-glencoco-v2-dark.svg",
    [LOGO_VARIATION.SMALL_WHITE]: "logo-glencoco-sm-white.png",
    [LOGO_VARIATION.GLENX]: "glenx-white-logo.webp",
    [LOGO_VARIATION.GLENX_DARK]: "glenx-logo.png",
  },
  (pathInBucket) => `${BASE_ASSET_PATH}${pathInBucket}`
);
