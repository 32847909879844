import { ListPillI } from "@/api/routes/list";
import { AccountUserListI } from "shared/lib/interfaces/account";
import { LIST_TYPES } from "@/modules/pipeline/lists/list/constants";

// Extracting that to a separate utility as that's reused across both lists view
// and account details. There's a custom behaviour for the Callback -> Nurture lists,
// which may potentially extend in the future.
export const getListName = (list?: AccountUserListI) => {
  if (!list) {
    return "N/A";
  }

  return list.list_type === LIST_TYPES.CALLBACKS ? "Nurture" : list.name;
};

export const checkIfCanAddLeadsToList = (list?: ListPillI) =>
  !list?.is_default_list && !list?.is_exclusive_list;
