import { XCircleIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import {
  PhoneRejectIcon,
  QuestionMarkIcon,
  ScriptSolidIcon,
} from "shared/ui/icons";
import { DAYS_OF_WEEK, MONTHS } from "shared/lib/constants/time";
import clsx from "clsx";
import dayjs from "dayjs";
import { EVENTS } from "shared/ui/activity-log/constants/events";
import { useEffectOnce } from "shared/lib/hooks";
import { AccountHistoryI } from "@/interfaces/accounts";
import { DISPOSITION_SPECIFICS } from "shared/lib/constants/dispositions";

const createActivityEvent = (
  Icon: JSX.Element,
  color: string,
  text: (arg?: string, callerName?: string) => string,
  dataKey?: string
) => {
  return { Icon, color, text: text, dataKey };
};

const ACTIVITY_EVENTS = {
  [EVENTS.NO_SHOW]: createActivityEvent(
    <XCircleIcon className="h-5 w-5 text-black/60" />,
    "red",
    (leadName?: string) => `${leadName} was a no-show`,
    "leadName"
  ),
  [DISPOSITION_SPECIFICS.GATEKEEPER_REJECTED]: createActivityEvent(
    <XCircleIcon className="h-5 w-5 text-black/60" />,
    "red",
    () => "Rejected by gatekeeper"
  ),
  [DISPOSITION_SPECIFICS.PITCH_REJECTED_DM]: createActivityEvent(
    <XCircleIcon className="h-5 w-5 text-black/60" />,
    "red",
    () => "Pitch was rejected"
  ),
  [DISPOSITION_SPECIFICS.INTRO_REJECTED_DM]: createActivityEvent(
    <XCircleIcon className="h-5 w-5 text-black/60" />,
    "red",
    () => "Intro was rejected"
  ),
  [DISPOSITION_SPECIFICS.MORE_MATERIALS]: createActivityEvent(
    <QuestionMarkIcon className="h-5 w-5 text-black/60" />,
    "yellow",
    () => "More materials requested"
  ),
  [DISPOSITION_SPECIFICS.VOICEMAIL_LEFT]: createActivityEvent(
    <PhoneRejectIcon className="h-5 w-5 text-black/60" />,
    "white",
    () => "Voicemail left"
  ),
  [DISPOSITION_SPECIFICS.VOICEMAIL_NOT_LEFT]: createActivityEvent(
    <PhoneRejectIcon className="h-5 w-5 text-black/60" />,
    "white",
    () => "Voicemail not left"
  ),
  [DISPOSITION_SPECIFICS.OTHER]: createActivityEvent(
    <ScriptSolidIcon className="h-5 w-5 text-black/60" />,
    "white",
    () => "Dispositioned as other"
  ),
  [DISPOSITION_SPECIFICS.NOT_QUALIFIED_BUDGET]: createActivityEvent(
    <XCircleIcon className="h-5 w-5 text-black/60" />,
    "red",
    () => "Disqualified for budget"
  ),
  [DISPOSITION_SPECIFICS.NOT_QUALIFIED_NEED]: createActivityEvent(
    <XCircleIcon className="h-5 w-5 text-black/60" />,
    "red",
    () => "Disqualified for no need"
  ),
  [DISPOSITION_SPECIFICS.NOT_QUALIFIED_TIMING]: createActivityEvent(
    <XCircleIcon className="h-5 w-5 text-black/60" />,
    "red",
    () => "Disqualified for timing"
  ),
  [DISPOSITION_SPECIFICS.NOT_QUALIFIED_AUTHORITY]: createActivityEvent(
    <XCircleIcon className="h-5 w-5 text-black/60" />,
    "red",
    () => "Disqualified for authority"
  ),
  [DISPOSITION_SPECIFICS.DO_NOT_CALL]: createActivityEvent(
    <XCircleIcon className="h-5 w-5 text-black/60" />,
    "white",
    () => "Do not call"
  ),
  [DISPOSITION_SPECIFICS.DATA_WRONG]: createActivityEvent(
    <XCircleIcon className="h-5 w-5 text-black/60" />,
    "white",
    () => "Data wrong"
  ),
  [DISPOSITION_SPECIFICS.BAD_PHONE_NUMBER]: createActivityEvent(
    <XCircleIcon className="h-5 w-5 text-black/60" />,
    "white",
    () => "Bad phone number"
  ),
};

const ActivityCardCallDisposition = ({
  activity,
}: {
  activity: AccountHistoryI;
}) => {
  const [isInit, setIsInit] = useState(false);

  useEffectOnce(() => {
    setIsInit(true);
  });

  const activityEvent =
    activity.call_disposition && ACTIVITY_EVENTS[activity.call_disposition];

  if (!activityEvent) return null;

  const { Icon, color, text, dataKey } = activityEvent;
  const { created_at: timestamp, notes, caller_name } = activity;

  const contactName = `${activity?.contact?.first_name || ""} ${
    activity?.contact?.last_name || ""
  }`;

  const titleText = text((activity as any)[dataKey as any], contactName);

  return (
    <div>
      <div
        className={clsx(
          "relative flex gap-2",
          "items-center",
          "transition-[opacity,top] duration-300",
          {
            "-top-5 opacity-0": !isInit,
            "top-0 opacity-100": isInit,
          }
        )}
      >
        <div
          className={clsx(
            "flex h-8 w-8 items-center justify-center rounded-full border border-black/10 bg-white",
            {
              "bg-[#ff5e3a80]": color === "red",
              "bg-[rgb(223,223,180)]": color === "yellow",
            }
          )}
        >
          {Icon}
        </div>

        <div>
          <p className="ae-typography-detail1 text-black/40">
            <span className="uppercase">
              {`${DAYS_OF_WEEK[dayjs(timestamp).day()]}, 
            ${MONTHS[dayjs(timestamp).month()]} 
            ${dayjs(timestamp).date()}`}
            </span>
          </p>

          {titleText && <p className="ae-typography-body2">{titleText}</p>}
        </div>
      </div>

      <div>
        {notes && (
          <div className="ae-typography-detail1 mt-2 break-keep rounded-md bg-black/5 p-2 text-black/60">
            {notes}
          </div>
        )}

        <div className="mt-1 flex items-center gap-1">
          <div className="flex h-[20px] w-[20px] items-center justify-center rounded-full border border-black/10 bg-white text-[6px] text-xs">
            {caller_name?.[0]}
            {activity.caller_name?.split(" ")?.[1]?.[0]}
          </div>
          <p className="ae-typography-detail1 text-black/40">{caller_name}</p>
        </div>
      </div>
    </div>
  );
};

export default ActivityCardCallDisposition;
