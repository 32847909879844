import {
  FC,
  cloneElement,
  useEffect,
  useRef,
  useState,
  PropsWithChildren,
  useMemo,
  isValidElement,
} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperI } from "swiper/types";

import "swiper/css";

import { clsxMerge } from "shared/lib/helpers";
import { SidebarLayout } from "shared/ui/sidebarV2/layout";
import { BackgroundBlur } from "shared/ui/sidebarV2/components/background-blur";

interface SidebarV2I extends PropsWithChildren {
  isOpen: boolean;
  onClose?: () => void;

  activeStepIndex?: number;
  onChangeStep?: (sidebarRef: SwiperI) => void;

  isFullPage?: boolean;

  /**
   * Needed in case if we want to keep the background page usable while sidebar
   * is open
   */
  allowPageInteraction?: boolean;

  containerClassName?: string;
  backgroundBlurClassName?: string;
}

// NOTE: Parent component using this <SidebarV2/> component is responsible for maintaining the activeSlideIndex and the open/close states.
// This component only receives it.
// Try using hook `useStepsManager()` to main activeSlideIndex and use hook `useDisclosure()` to maintain open/close states.

const SidebarV2: FC<SidebarV2I> = ({
  isOpen: isOpenProp,
  onClose = () => {},

  children,
  activeStepIndex = 0,
  onChangeStep = () => {},

  isFullPage = true,
  allowPageInteraction,

  containerClassName,
  backgroundBlurClassName,
}) => {
  const sidebarContentRef = useRef<SwiperI>();
  const [isOpenState, setIsOpenState] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const initializeSwiper = (swiper: SwiperI) => {
    sidebarContentRef.current = swiper;
  };

  const handleChangeStep = () => {
    if (sidebarContentRef.current) {
      onChangeStep(sidebarContentRef.current);
    }
  };

  const jumpToSlide = (targetIndex: number) => {
    sidebarContentRef.current?.slideTo(targetIndex);
  };

  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  useEffect(() => {
    jumpToSlide(activeStepIndex);
  }, [activeStepIndex]);

  useEffect(() => {
    if (isOpenProp) {
      setIsOpenState(true);

      setTimeout(() => {
        setIsVisible(true);

        /**
         * Jump to activeStepIndex in case activeStepIndex was not reset in
         * parent prop after the sidebar was last closed.
         */
        jumpToSlide(activeStepIndex);
      }, 100);
    } else {
      setIsVisible(false);

      /**
       * Give visibility animation time to complete before removing component
       * from DOM
       */
      setTimeout(() => setIsOpenState(false), 500);
    }
  }, [isOpenProp]);

  const swiperContent = useMemo(() => {
    const slideContentProps = { onClose: handleClose };

    if (Array.isArray(children)) {
      return children.map((child, index: number) => (
        <SwiperSlide
          key={index}
          className={clsxMerge("w-fit", {
            hidden: activeStepIndex !== index,
          })}
        >
          {() => cloneElement(child, slideContentProps)}
        </SwiperSlide>
      ));
    }

    if (isValidElement(children)) {
      return (
        <SwiperSlide className="w-fit">
          {cloneElement(children, slideContentProps)}
        </SwiperSlide>
      );
    }

    return null;
  }, [handleClose, activeStepIndex, children]);

  return isOpenState ? (
    <SidebarLayout
      isVisible={isVisible}
      allowPageInteraction={allowPageInteraction}
      isFullPage={isFullPage}
      onClickOverlay={handleClose}
    >
      <SidebarLayout.Drawer
        className={containerClassName}
        isVisible={isVisible}
      >
        <Swiper
          className="swiper-no-swiping h-full w-full"
          onSwiper={initializeSwiper}
          onSlideChange={handleChangeStep}
          effect="fade"
          speed={10}
        >
          {/* NOTE: the below `onClose` will only be passed to the child if the
          child accepts an `onClose` prop */}
          {swiperContent}
        </Swiper>

        <BackgroundBlur
          isBlurVisible={allowPageInteraction}
          className={backgroundBlurClassName}
        />
      </SidebarLayout.Drawer>
    </SidebarLayout>
  ) : null;
};

export default SidebarV2;
