import { FC, useCallback, useEffect, useState } from "react";

import {
  AttachmentI,
  EmailTemplateI,
  GetEmailGenerationStatusResponseI,
  SendEmailRequestParamsI,
} from "@/api/routes/email";
import { GenerateEmailStreamRequestParamsI } from "@/api/routes/email-webai";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import {
  useGenerateAIEmail,
  useSendEmail,
  useGenerateAIEmailStreamBody,
  useGenerateAIEmailStreamSubject,
} from "@/modules/email-templates-sidebar/queries";
import { useEmailTemplatesSidebarContext } from "@/modules/email-templates-sidebar/context";
import { EmailForm } from "./email-form";

interface EmailTemplatePropsI {
  template: EmailTemplateI;
  emailId: string;
  senderEmail?: string;
  campaignAttachments?: AttachmentI[];
}

export const EmailTemplate: FC<EmailTemplatePropsI> = ({
  template,
  emailId,
  senderEmail,
  campaignAttachments,
}) => {
  const { onClose } = useEmailTemplatesSidebarContext();
  const {
    setFocusedContact,
    accountHistoryData: { reloadData: reloadAccountHistory },
  } = useAccountDetailsContext();

  const { mutateAsync: generateAIEmailAsync } = useGenerateAIEmail();
  const { mutateAsync: generateAIEmailBodyStreamAsync } =
    useGenerateAIEmailStreamBody();
  const { mutateAsync: generateAIEmailSubjectStreamAsync } =
    useGenerateAIEmailStreamSubject();
  const { mutateAsync: sendEmailAsync, isPending: isSendEmailPending } =
    useSendEmail();

  const [isAIGenerationComplete, setIsAIGenerationComplete] = useState(false);
  const [isAIGenerationStarted, setIsAIGenerationStarted] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const [emailData, setEmailData] =
    useState<GetEmailGenerationStatusResponseI>();

  const handleStream = async (
    isBody: boolean,
    parameters: GenerateEmailStreamRequestParamsI
  ) => {
    try {
      let resp;
      if (isBody) {
        resp = await generateAIEmailBodyStreamAsync({
          parameters: parameters,
        });
      } else {
        resp = await generateAIEmailSubjectStreamAsync({
          parameters: parameters,
        });
      }

      if (!resp.body) {
        throw new Error("ReadableStream not supported in this browser.");
      }

      const reader = resp.body?.getReader();
      const decoder = new TextDecoder();

      let done = false;

      while (!done) {
        const readResult = await reader?.read();
        const value = readResult?.value;
        const readerDone = readResult?.done;

        done = readerDone ?? true;

        if (value) {
          const chunk = decoder.decode(value);

          if (isBody) {
            setEmailData((prev) => ({
              ...prev,
              body: (prev?.body || "") + chunk,
            }));
          } else {
            setEmailData((prev) => ({
              ...prev,
              subject: (prev?.subject || "") + chunk,
            }));
          }
        }
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleComposeWithAI = useCallback(async () => {
    if (isAIGenerationStarted) {
      // still on-going AI generation
      return;
    }

    setIsAIGenerationStarted(true);
    setIsAIGenerationComplete(false);

    let success = false;

    if (template.id && emailId) {
      const resp = await generateAIEmailAsync({
        templateId: template.id,
        parameters: { email_id: emailId },
      });

      if (resp.status === 200) {
        const parameters: GenerateEmailStreamRequestParamsI = {
          email_id: emailId,
          campaign_id: resp.data.campaign_id,
          contact_email: resp.data.contact_email,
          account_id: resp.data.account_id,
          contact_id: resp.data.contact_id,
          account_name: resp.data.account_name,
          account_industry: resp.data.account_industry,
          account_city: resp.data.account_city,
          account_state: resp.data.account_state,
          account_headcount: resp.data.account_headcount,
          account_website: resp.data.account_website,
          email_signature: resp.data.email_signature,
          user_prompt: resp.data.user_prompt,
        };

        // stream email subject

        await Promise.all([
          handleStream(false, parameters), // stream email subject
          handleStream(true, parameters), // stream email body
        ]);

        success = true;
      }
    }

    setIsAIGenerationComplete(true);
    setIsAIGenerationStarted(false);

    return success;
  }, [template.id, emailId]);

  const handleEmailSend = useCallback(
    async (data: SendEmailRequestParamsI) => {
      if (emailId) {
        const resp = await sendEmailAsync({
          emailId: emailId,
          parameters: data,
        });

        if (resp.status === 200) {
          onEmailSuccess();
          return true;
        }
      }

      return false;
    },
    [emailId, onClose]
  );

  const onEmailSuccess = () => {
    setIsEmailSent(true);
    reloadAccountHistory();
    setFocusedContact(undefined);
    onClose();
  };

  useEffect(() => {
    // reset/initialize content for selected template
    setEmailData(undefined);
    setIsEmailSent(false);
    setIsAIGenerationComplete(false);
    setIsAIGenerationStarted(false);
  }, [template.id]);

  return (
    <div className="relative flex h-full flex-col justify-center">
      <div>
        <EmailForm
          //  key is used to force entire component to re-render when template changes so that all form fields can be reset
          key={template.id}
          template={template}
          senderEmail={senderEmail}
          attachmentOptions={campaignAttachments}
          initialSubject={emailData?.subject}
          initialBody={emailData?.body}
          onComposeWithAI={handleComposeWithAI}
          onSend={handleEmailSend}
          isSendingEmail={isSendEmailPending}
          isSendEmailSuccess={isEmailSent}
          isAIGenerationComplete={isAIGenerationComplete}
          isAIGenerating={isAIGenerationStarted}
        />
      </div>
    </div>
  );
};
