import { AxiosInstance, AxiosResponse } from "axios";
import {
  InfinitePaginationRequestI,
  InfinitePaginationResponseI,
  SortRequestI,
} from "shared/lib/interfaces/api";
import { PipelineListContactI } from "@/interfaces/pipeline/list";
import { ListMemberUpdateMessageI } from "@/interfaces/list-dialing";
import { ListSubsectionI } from "@/modules/pipeline/lists/list/interfaces";
import { AccountUserListTypeI } from "shared/lib/interfaces/account";
import { ListTableColumnI } from "@/modules/pipeline/lists/list/workspace/table/interfaces";

export interface ListPillI {
  id: string;
  name: string;
  description: string;
  order: number;
  is_default_list: boolean;
  is_exclusive_list: boolean;
  list_type: AccountUserListTypeI;
  cold_count?: number;
  nurture_count?: number;
}

export interface CreateOrUpdateListRequestParamsI {
  name: string;
  description?: string;
}

export interface GetListResponseI extends InfinitePaginationResponseI {
  contacts: PipelineListContactI[];
}

export interface CreateListResponseI {
  list_id: string;
}

export interface BulkUpdateListsRequestI {
  lists: {
    list_id: string;
    name: string;
    description: string;
    order: number;
  }[];
}

export interface GetListsRequestFilterParamsI {
  timezone_filters?: string[];
  campaign_filters?: string[];
  list_subsection?: ListSubsectionI;
  filter_available_to_call?: boolean;
}

export type GetListsRequestParamsI = InfinitePaginationRequestI &
  SortRequestI<ListTableColumnI> &
  GetListsRequestFilterParamsI;

export interface GetListsResponseI {
  user_lists: ListPillI[];
}

export interface GetListMemberResponseI {
  contact: PipelineListContactI;
}

export interface ContactToListI {
  contact_id: string;
  campaign_id: string;
}

export interface BulkAddContactsToListRequestI {
  contacts: ContactToListI[];
  list_ids: string[];
}

export interface BulkActionBaseRequestI {
  membership_ids: string[];
}

export interface ListCampaignI {
  id: string;
  name: string;
  logo_url: string;
}

export interface GetListDetailsResponseI {
  name: string;
  description: string;
  campaigns: ListCampaignI[];
  is_default_list: boolean;
  number_total_contacts: number;
  number_cold_contacts: number;
  number_nurture_contacts: number;
  number_available_contacts_to_call_cold: number;
  number_available_contacts_to_call_nurture: number;
  // NOTE list_type is not here yet. If you need it, ask Jason to add it.
}

export type BulkRemoveContactsFromListRequestI = BulkActionBaseRequestI;

export interface BulkDisqualifyContactsFromListRequestI
  extends BulkActionBaseRequestI {
  reason: string;
}

export type SearchContactsRequestI = InfinitePaginationRequestI & {
  search_term: string;
};

export interface GetConnectedListMemberResponseI
  extends ListMemberUpdateMessageI {}

export interface ListApiI {
  getLists: () => Promise<AxiosResponse<GetListsResponseI>>;
  createList: (
    listParameters: CreateOrUpdateListRequestParamsI
  ) => Promise<AxiosResponse<CreateListResponseI>>;

  getListDetails: (
    listId: string
  ) => Promise<AxiosResponse<GetListDetailsResponseI>>;

  getListMember: (
    listId: string,
    membershipId: string
  ) => Promise<AxiosResponse<GetListMemberResponseI>>;

  updateList: (
    listId: string,
    listParameters: CreateOrUpdateListRequestParamsI
  ) => Promise<AxiosResponse>;

  deleteList: (listId: string) => Promise<AxiosResponse>;

  // The new list contacts endpoint that supports nurturing / cold lists.
  getListContacts: (
    listId: string,
    parameters?: GetListsRequestParamsI
  ) => Promise<AxiosResponse<GetListResponseI>>;

  //Used to update leads for a specific account in the current list
  //which is also includes currently dialing list
  getListContactsByAccountId: (
    campaignId: string,
    listId: string,
    accountId: string,
    queryParams: {
      list_subsection: ListSubsectionI;
    }
  ) => Promise<AxiosResponse<GetListResponseI>>;

  bulkUpdateLists: (lists: BulkUpdateListsRequestI) => Promise<AxiosResponse>;

  bulkAddContactsToListRequest: (
    params: BulkAddContactsToListRequestI
  ) => Promise<AxiosResponse>;

  bulkRemoveContactsFromListRequest: (
    listId: string,
    params: BulkRemoveContactsFromListRequestI
  ) => Promise<AxiosResponse>;

  bulkDisqualifyContactsFromListRequest: (
    listId: string,
    params: BulkDisqualifyContactsFromListRequestI
  ) => Promise<AxiosResponse>;

  searchContacts: (
    parameters: SearchContactsRequestI
  ) => Promise<AxiosResponse<GetListResponseI>>;

  getConnectedListMember: (
    listId: string,
    callId: string
  ) => Promise<AxiosResponse<GetConnectedListMemberResponseI>>;
}

export const ListAPIEndpoints = (API: AxiosInstance): ListApiI => ({
  getListContacts: (listId: string, params) =>
    API.post(`/v2/list/${listId}`, params),

  getLists: () => API.get("/v1/lists"),

  createList: (listParameters: CreateOrUpdateListRequestParamsI) =>
    API.post("/v1/lists", listParameters),

  bulkUpdateLists: (params) => API.post(`/v1/bulk_list_updates`, params),

  getListDetails: (listId) => API.get(`/v1/lists/${listId}/details`),

  updateList: (listId, listParameters) =>
    API.patch(`/v1/lists/${listId}`, listParameters),

  deleteList: (listId) => API.delete(`/v1/lists/${listId}`),

  getListContactsByAccountId: (campaignId, listId, accountId, queryParams) =>
    API.get(
      `/v1/list/${listId}/account/${accountId}/campaign/${campaignId}/leads`,
      {
        params: queryParams,
      }
    ),

  bulkAddContactsToListRequest: (params) =>
    API.post(`/v1/list/bulk_add_contacts`, params),

  bulkRemoveContactsFromListRequest: (listId, params) =>
    API.post(`/v1/list/${listId}/bulk_remove_contacts`, params),

  bulkDisqualifyContactsFromListRequest: (listId, params) =>
    API.post(`/v1/list/${listId}/bulk_disqualify_leads`, params),

  searchContacts: (parameters) => API.post(`/v1/list_search`, parameters),

  getListMember: (listId, membershipId) =>
    API.get(`/v1/list/${listId}/membership/${membershipId}/status`),

  getConnectedListMember: (listId, callId) =>
    API.get(`/v1/list/${listId}/call/${callId}/get_membership`),
});
