import { FC, PropsWithChildren } from "react";

import { clsxMerge } from "shared/lib/helpers";

import { NotificationsPanelTabs } from "./tabs";
import { NotificationsPanelEmpty } from "./empty";
import { MarkAllReadButton } from "./mark-all-read-button";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { NotificationsTabI } from "./interfaces";

interface NotificationsPanelPropsI
  extends PropsWithClassNameI,
    PropsWithChildren {
  unreadCounter?: number;
  isEmpty?: boolean;
  onTabChange?: (tabId: NotificationsTabI) => void;
  onMarkReadAll?: () => void;
}

export const NotificationsPanel: FC<NotificationsPanelPropsI> = ({
  className,
  children,
  unreadCounter,
  isEmpty: _isEmpty,

  onMarkReadAll,
  onTabChange,
}) => {
  // Note TODO recursively look if all children are false or empty
  const isEmpty = typeof _isEmpty === "boolean" ? _isEmpty : !children;

  return (
    <div
      className={clsxMerge(
        "z-[999] w-[100vw] max-w-[700px] bg-white",
        "rounded-lg drop-shadow-md",
        className
      )}
    >
      <div className="px-6 pt-6">
        <div className="mb-6 flex items-center justify-between">
          <h3 className="brand-typography-h2">Notifications</h3>
          {!!unreadCounter && <MarkAllReadButton onClick={onMarkReadAll} />}
        </div>

        <NotificationsPanelTabs
          unreadCounter={unreadCounter}
          onChange={onTabChange}
        />
      </div>

      <div
        className={clsxMerge(
          "border-t border-t-black/20",
          "max-h-[60vh] overflow-auto",
          {
            "p-6": isEmpty,
          }
        )}
      >
        {isEmpty ? <NotificationsPanelEmpty /> : children}
      </div>
    </div>
  );
};
