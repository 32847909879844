import { AxiosInstance } from "axios";
import {
  NotificationCountersI,
  NotificationI,
} from "@/interfaces/notification";
import { NotificationsTabI } from "shared/ui/notifications/panel/interfaces";
import { NOTIFICATIONS_TABS } from "shared/ui/notifications/panel/tabs";

export interface GetNotificationsResponseI {
  next_token?: string;
  notifications: NotificationI[];
}

export type GetNotificationsCountResponseI = NotificationCountersI;

export const NotificationsAPIEndpoints = (api: AxiosInstance) => ({
  getNotifications: ({
    section,
    nextToken,
  }: {
    section: NotificationsTabI;
    nextToken?: string | null;
  }) =>
    api.post<GetNotificationsResponseI>("/v2/notifications", {
      unread: section === NOTIFICATIONS_TABS.UNREAD || undefined,
      next_token: nextToken,
    }),

  getNotificationsCount: () =>
    api.get<GetNotificationsCountResponseI>("/v2/notifications/count"),

  markReadNotification: (notificationId: string) =>
    api.post(`/v1/notification/${notificationId}/mark_read`),

  markReadAllNotifications: () => api.post("/v1/notifications/mark_all_read"),
});
