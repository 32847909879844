import { ListPillI } from "@/api/routes/list";

import { NURTURE_LIST_TYPES } from "@/modules/pipeline/lists/list/constants";
import { LiteralTypeFromArray } from "shared/lib/interfaces/utils";

export const checkIfNurtureList = (list: ListPillI | undefined) => {
  /**
   * `null` means that the list is not loaded yet, and it's too early to
   * determine which type of list it is.
   */
  if (!list) {
    return null;
  }

  /**
   * ACCOUNT_LIST_TYPES.CALLBACKS & ACCOUNT_LIST_TYPES.MISSED_CALLS are basically
   * the new nurture, not including because of that.
   */
  return NURTURE_LIST_TYPES.includes(
    list.list_type as LiteralTypeFromArray<typeof NURTURE_LIST_TYPES>
  );
};

export const getSortedLists = (lists: ListPillI[] | undefined) => {
  if (lists) {
    return [...lists].sort((a, b) =>
      a.is_exclusive_list ? -1 : a.order - b.order
    );
  }

  return [];
};
