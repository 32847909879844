import { FC, useMemo } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import clsx from "clsx";

import TextFieldFactory from "shared/ui/user-input/text-field";
import TextAreaFactory from "shared/ui/user-input/text-area";
import Modal, { ModalChildrenFnPropsI } from "shared/ui/modal";
import { getEmailsFromString, yupEmailListSchema } from "shared/lib/helpers";
import { APII, glencocoClientAPI } from "@/api/glencoco";
import { useApi } from "shared/lib/hooks/use-api";
import { ReplyAccountContactEmailRequestParamsI } from "@/api/routes/account/interfaces";

/**
 * Email may be passed either as a pure email string or in "Name Surname <hello@world.com>"
 * format, we need to extract it in a pure format.
 *
 * @param rawEmail
 */
const extractEmail = (rawEmail: string | null | undefined) => {
  if (!rawEmail) {
    return null;
  }

  const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/;
  const match = rawEmail.match(emailRegex);

  return match ? match[0] : null;
};

export interface AccountReplyEmailModalPropsI {
  replyToEmail?: string;
  emailId?: string;
  onSuccess: () => void;
  onClose?: () => void;
}

interface AccountSendEmailFormValuesI {
  emailsRaw: string;
  notes: string;
}

export const ACCOUNT_REPLY_EMAIL_MODAL_ID = "account-reply-email-modal";

const Input = TextFieldFactory(Field);
const Textarea = TextAreaFactory(Field);

const sendAccountContactEmailValidationSchema = Yup.object().shape({
  emailsRaw: yupEmailListSchema,
  notes: Yup.string().required("Email body is required"),
});

const replyAccountContactEmailFetcher = (
  api: APII,
  emailId: string,
  request: ReplyAccountContactEmailRequestParamsI
) => api.replyAccountContactEmail(emailId, request);

const AccountReplyEmailModalContent: FC<
  ModalChildrenFnPropsI & AccountReplyEmailModalPropsI
> = ({ handleClose, onSuccess, replyToEmail, emailId }) => {
  const [, requestReplyEmail] = useApi({
    apiFactory: glencocoClientAPI,
    shouldCallAutomatically: false,
    fetcher: replyAccountContactEmailFetcher,
    onSuccess: () => {
      onSuccess();
      handleClose();
    },
    showToastOnSuccess: true,
    successBuilder: () => "Email reply has been requested successfully!",
    errorBuilder: (response) =>
      response.status === 401
        ? "The prospect requested not to email"
        : "Failed to reply to email. Please try again later.",
    onError: handleClose,
  });

  const preparedReplyToEmail = useMemo(
    () => extractEmail(replyToEmail),
    [replyToEmail]
  );

  const formInitialValues: AccountSendEmailFormValuesI = useMemo(
    () => ({
      emailsRaw: preparedReplyToEmail || "",
      notes: "",
    }),
    [preparedReplyToEmail]
  );

  const handleSubmit = ({ notes, emailsRaw }: AccountSendEmailFormValuesI) => {
    if (!emailId || !preparedReplyToEmail) {
      toast.error(
        "Email ID and reply-to email address are required to send an email."
      );

      return;
    }

    return requestReplyEmail(emailId, {
      email_recipients: getEmailsFromString(emailsRaw),
      email_body: notes,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
      validationSchema={sendAccountContactEmailValidationSchema}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form className="flex flex-col">
          <Input
            name="emailsRaw"
            label="Send Reply Email(s) To (comma-separated):"
            placeholder="Enter email addresses for a reply email to be sent"
            errors={errors.emailsRaw}
            touched={touched.emailsRaw}
          />

          <Textarea
            name="notes"
            label="Email body"
            placeholder="Share any notes you have about what to put in the email"
            className="mb-4"
            errors={errors.notes}
            touched={touched.notes}
          />

          <div className="flex justify-end gap-2">
            <button type="button" className="btn-nofill" onClick={handleClose}>
              Cancel
            </button>

            <button
              type="submit"
              className={clsx("btn-ae-default w-[150px]", {
                disabled: isSubmitting,
              })}
            >
              Send
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export const AccountReplyEmailModal: FC<AccountReplyEmailModalPropsI> = (
  props
) => (
  <Modal
    id={ACCOUNT_REPLY_EMAIL_MODAL_ID}
    title="Reply to Email"
    onClose={props.onClose}
  >
    {(modalContentProps) => (
      <AccountReplyEmailModalContent {...modalContentProps} {...props} />
    )}
  </Modal>
);
