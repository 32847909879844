import { getS3AssetPath } from "shared/lib/helpers";

export const getLandingImageAssetPath = (imageName: string) =>
  getS3AssetPath(`landing/version-2/${imageName}`);

/**
 * Get the image asset path for the customer platform from `glencoco-assets` bucket.
 * This function auto adds `platform/customer/` prefix the image URL.
 *
 * @param imageName - Path of the image after the `platform/customer/` prefix.
 */
export const getImageAssetPath = (imageName: string) =>
  getS3AssetPath(`platform/customer/${imageName}`);
