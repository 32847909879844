import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { AppLayoutPagePropsI } from "@/interfaces/layout";

type AppLayoutProviderPropsI = PropsWithChildren;

interface AppLayoutContextI {
  appLayoutPageProps: AppLayoutPagePropsI;
  setAppLayoutNavTitle: (navTitle?: ReactNode) => void;
  setAppLayoutPageProps: Dispatch<SetStateAction<AppLayoutPagePropsI>>;
}

const AppLayoutContext = createContext<AppLayoutContextI>({
  appLayoutPageProps: {},
  setAppLayoutNavTitle: () => {},
  setAppLayoutPageProps: () => {},
});

export const useAppLayoutContext = (): AppLayoutContextI =>
  useContext(AppLayoutContext);

export const useSetLayoutParams = (params: AppLayoutPagePropsI) => {
  const { setAppLayoutPageProps } = useAppLayoutContext();

  useEffect(() => {
    setAppLayoutPageProps((oldParams) => ({
      ...oldParams,
      ...params,
    }));
  }, [params]);
};

export const AppLayoutProvider: FC<AppLayoutProviderPropsI> = ({
  children,
}) => {
  const [appLayoutPageProps, setAppLayoutPageProps] =
    useState<AppLayoutPagePropsI>({});

  const setAppLayoutNavTitle = useCallback(
    (navTitle?: ReactNode) =>
      setAppLayoutPageProps((currentLayoutPageProps) => ({
        ...currentLayoutPageProps,
        navTitle,
      })),
    []
  );

  return (
    <AppLayoutContext.Provider
      value={{
        appLayoutPageProps,
        setAppLayoutNavTitle,
        setAppLayoutPageProps,
      }}
    >
      {children}
    </AppLayoutContext.Provider>
  );
};
