/**
 * 3rd PARTY LIBRARIES
 **/
import axios, { AxiosInstance } from "axios";

/**
 * SHARED LIBRARIES
 **/
import Cookies from "shared/lib/helpers/cookies";

/**
 * CONSTANTS
 */
import { COOKIES_GLCC_ACCESS_TOKEN } from "@/constants/cookies";

/**
 * HELPERS / UTILS
 */
import { StreamAPIConfig } from "@/api/stream";
import { interceptors } from "./interceptors";

/**
 * API ROUTES
 */
import {
  EmailWebAIAPIEndpoints,
  EmailWebAIAPII,
} from "@/api/routes/email-webai";
import { getAuthToken } from "lib/auth/token";

export interface DefaultResponseI {
  error_code?: number;
  status: 0;
}

const applyInterceptors = interceptors;

export interface APII {
  Instance: AxiosInstance;
}

export interface StreamAPII extends EmailWebAIAPII {}

const requests = (API: AxiosInstance): APII => ({
  Instance: API,
});

const streamRequests = (API: StreamAPIConfig): StreamAPII => ({
  ...EmailWebAIAPIEndpoints(API),
});

export const webAIServerAPI = ({
  token,
  req,
  res,
}: {
  token?: string;
  req?: any;
  res?: any;
}) => {
  let auth0Token;

  if (token) {
    auth0Token = token;
  } else if (req && res) {
    const cookies = Cookies({ req, res });

    auth0Token = cookies.getCookie(COOKIES_GLCC_ACCESS_TOKEN);
  }

  const API = axios.create({
    baseURL: process.env.NEXT_PUBLIC_AI_API_DOMAIN,
    maxRedirects: 0,
    timeout: process.env.NEXT_PUBLIC_ENV === "production" ? 10000 : 30000,
    responseType: "json",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      "accept-encoding": "*",
      Authorization: `Bearer ${auth0Token}`,
    },
  });

  applyInterceptors(API);

  return requests(API);
};

export const webAIClientAPI = () => {
  const authToken = getAuthToken();

  const API = axios.create({
    baseURL: process.env.NEXT_PUBLIC_AI_API_DOMAIN,
    maxRedirects: 0,
    timeout: process.env.NEXT_PUBLIC_ENV === "production" ? 20000 : 30000,
    responseType: "json",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });

  applyInterceptors(API);

  return requests(API);
};

export const webAIClientStreamAPI = () => {
  const authToken = getAuthToken();

  const API: StreamAPIConfig = {
    baseURL: process.env.NEXT_PUBLIC_AI_API_DOMAIN ?? "",
    timeout: process.env.NEXT_PUBLIC_ENV === "production" ? 20000 : 60000,
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  // Since Fetch doesn't have interceptors, we can create a wrapper function
  // to apply any request/response transformations or error handling
  // For example, you can create a custom fetch function

  return streamRequests(API);
};
