import { NotificationsTabI } from "shared/ui/notifications/panel/interfaces";

const LOCAL_NOTIFICATIONS_COMMON_KEY = "local-notifications";

export const LocalNotificationsQueryKeys = {
  notifications: (section: NotificationsTabI) => [
    LOCAL_NOTIFICATIONS_COMMON_KEY,
    section,
  ],
};
