import { InputHTMLAttributes } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

type InputPropsI = InputHTMLAttributes<HTMLInputElement>;

export interface TextFieldPropsI extends PropsWithClassNameI {
  name?: string;
  label?: string;
  placeholder: string;
  errors?: string;
  touched?: boolean;
  disabled?: boolean;
  inputProps?: InputPropsI;
  inputClassName?: string;
  labelClassName?: string;
  labelContentClassName?: string;
  isErrorsAbsolutePosition?: boolean;
}

const DefaultInput = (props: InputPropsI) => <input {...props} />;

const TextField =
  (Field = DefaultInput) =>
  // eslint-disable-next-line react/display-name
  ({
    name,
    label,
    placeholder,
    errors,
    touched,
    className,
    disabled,
    inputClassName,
    inputProps = {},
    isErrorsAbsolutePosition,
    labelClassName,
    labelContentClassName,
  }: TextFieldPropsI) =>
    (
      <div className={clsxMerge("form-control mb-4 w-full", className)}>
        {!!label && (
          <label className={clsxMerge("label", labelClassName)}>
            <span
              className={clsxMerge(
                "label-text font-bold",
                labelContentClassName
              )}
            >
              {label}
            </span>
          </label>
        )}

        <Field
          name={name}
          type="text"
          placeholder={placeholder}
          disabled={disabled}
          className={clsxMerge(
            "input input-bordered grow",
            "transition-all duration-300",
            "outline-none",
            errors && touched && "border-error-content",
            inputClassName
          )}
          {...inputProps}
        />

        {errors && touched && (
          <div className="relative">
            <div
              className={clsxMerge(
                "mt-1 flex items-center text-xs",
                isErrorsAbsolutePosition && "absolute"
              )}
            >
              <ExclamationCircleIcon className="text-error-content mr-1 h-5 w-5 bg-none" />
              <div className="text-error-content">{errors}</div>
            </div>
          </div>
        )}
      </div>
    );

export default TextField;
