import {
  ChangeEventHandler,
  FC,
  MutableRefObject,
  ReactNode,
  useRef,
  useState,
} from "react";
import { Portal } from "react-portal";

import { clsxMerge } from "shared/lib/helpers/styles";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { CloseIcon } from "../icons";

type ModalTriggerRef = MutableRefObject<HTMLInputElement>;

export interface ModalChildrenFnPropsI {
  modalTriggerRef: ModalTriggerRef;
  modalManager: typeof modalHelpers;
  handleClose: () => void;
}

export interface ModalPropsI {
  id: string;

  onOpen?: () => void;
  onClose?: () => void;

  unmountOnClose?: boolean;
  showCloseButton?: boolean;

  titleImage?: ReactNode;
  title?: string;
  description?: string;
  children: ReactNode | FC<ModalChildrenFnPropsI>;

  // Supported classNames
  titleImageClassName?: string;
  modalContainerClassName?: string;
  modalBoxClassName?: string;
  closeButtonClassName?: string;
  closeIconClassName?: string;
  titleClassName?: string;
  descriptionClassName?: string;
}

const UNMOUNT_MODAL_CONTENT_TIMEOUT_MS = 500;

const Modal: FC<ModalPropsI> = ({
  id: modalId,
  onOpen,
  onClose,
  titleImage,
  title,
  description,
  unmountOnClose = true,
  showCloseButton = true,
  children,

  titleImageClassName,
  modalContainerClassName,
  modalBoxClassName,
  closeButtonClassName,
  closeIconClassName,
  titleClassName,
  descriptionClassName,
}) => {
  const modalTriggerRef = useRef() as ModalTriggerRef;
  const [isVisible, setIsVisible] = useState(false);

  const handleClose = () => {
    modalHelpers.close(modalId);

    onClose?.();

    if (unmountOnClose) {
      setTimeout(() => {
        setIsVisible(false);
      }, UNMOUNT_MODAL_CONTENT_TIMEOUT_MS);
    }
  };

  const modalTriggerChangeHandler: ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    if (e.target.checked) {
      onOpen?.();

      if (!isVisible && unmountOnClose) {
        setIsVisible(true);
      }
    } else {
      onClose?.();
    }
  };

  const handleClickOnTheOutsideModalArea = (e: any) => {
    if (
      e.target.attributes.getNamedItem("data-target")?.value ===
      "outside-modal-area"
    ) {
      handleClose();
    }
  };

  return (
    <Portal>
      <input
        type="checkbox"
        id={modalId}
        className="modal-toggle"
        ref={modalTriggerRef}
        onChange={modalTriggerChangeHandler}
      />

      <div
        className="modal cursor-pointer"
        data-target="outside-modal-area"
        onClick={handleClickOnTheOutsideModalArea}
      >
        <div
          className={clsxMerge(
            "flex cursor-default justify-center",
            modalContainerClassName
          )}
        >
          <div
            className={clsxMerge(
              "modal-box relative rounded-[20px] p-6",
              "w-[600px] max-w-none",
              modalBoxClassName
            )}
          >
            {showCloseButton && (
              <button
                onClick={handleClose}
                className={clsxMerge(
                  "btn-ghost absolute right-6 top-4",
                  "z-10 rounded-full p-1",
                  closeButtonClassName
                )}
              >
                <CloseIcon
                  className={clsxMerge("h-6 w-6", closeIconClassName)}
                />
              </button>
            )}

            {titleImage && (
              <div className={clsxMerge("mb-3 w-full", titleImageClassName)}>
                {titleImage}
              </div>
            )}

            {title && (
              <h3 className={clsxMerge("typography-h2 mb-3", titleClassName)}>
                {title}
              </h3>
            )}

            {description && (
              <p
                className={clsxMerge(
                  "typography-body mb-5 text-black/60",
                  descriptionClassName
                )}
              >
                {description}
              </p>
            )}

            {isVisible && (
              <>
                {typeof children === "function"
                  ? children({
                      handleClose,
                      modalTriggerRef,
                      modalManager: modalHelpers,
                    })
                  : children}
              </>
            )}
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default Modal;
