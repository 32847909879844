import { FC } from "react";

import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

interface BackgroundBlurPropsI extends PropsWithClassNameI {
  isBlurVisible?: boolean;
}

export const BackgroundBlur: FC<BackgroundBlurPropsI> = ({
  isBlurVisible,
  className,
}) => (
  <div
    className={clsxMerge(
      "absolute left-0 top-0 -z-10 h-full w-full bg-[#F2F2F2]",
      isBlurVisible && "backdrop-blur-lg",
      className
    )}
  />
);
