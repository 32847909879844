import { FC, useMemo } from "react";
import toast from "react-hot-toast";

import { NotificationsPanel } from "shared/ui/notifications/panel";

import { LoadingSkeleton } from "./components/loading-skeleton";
import {
  LocalNotificationsProvider,
  useLocalNotificationsContext,
} from "./context";
import { useGlobalNotificationsContext } from "@/context/notifications-context";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { Notification } from "@/modules/notifications/notification";
import { clsxMerge } from "shared/lib/helpers";
import { InfiniteScrollWithQuery } from "shared/ui/infinite-scroll-with-observer/react-query";

interface NotificationsModulePropsI extends PropsWithClassNameI {
  onClose?: () => void;
}

const NotificationsModuleContent: FC<PropsWithClassNameI> = ({ className }) => {
  const { notificationsApi, setSelectedSection, readAllNotifications } =
    useLocalNotificationsContext();
  const { notificationCounters } = useGlobalNotificationsContext();

  const areNotificationsEmpty = useMemo(
    () =>
      !notificationsApi.isFetching &&
      !!notificationsApi.data &&
      notificationsApi.data.length === 0,
    [notificationsApi.isFetching, notificationsApi.data]
  );

  const handleOnMarkReadAll = async () => {
    try {
      await readAllNotifications();
    } catch (e) {
      toast.error("Failed to mark as read all notifications.");
    }
  };

  return (
    <NotificationsPanel
      className={className}
      unreadCounter={notificationCounters?.unread}
      onMarkReadAll={handleOnMarkReadAll}
      onTabChange={setSelectedSection}
      isEmpty={areNotificationsEmpty}
    >
      <InfiniteScrollWithQuery
        scrollIndicatorId="notifications-scroll-indicator"
        isDefaultMessageHidden={notificationsApi.isFetching}
        api={{
          ...notificationsApi,
          isFetching: notificationsApi.isFetching || notificationsApi.isLoading,
          isEmpty: areNotificationsEmpty,
        }}
        loading={<LoadingSkeleton />}
      >
        {notificationsApi.data?.map((notification, idx) => (
          <Notification
            key={idx}
            {...notification}
            className={clsxMerge({
              "border-b border-b-black/20":
                idx < notificationsApi.data.length - 1,
            })}
          />
        ))}
      </InfiniteScrollWithQuery>
    </NotificationsPanel>
  );
};

/**
 * NOTE
 * On notification action click before action and after actions happened
 * is controlled in BaseNotification component
 */
export const NotificationsModule: FC<NotificationsModulePropsI> = ({
  className,
  onClose,
}) => (
  <LocalNotificationsProvider onClosePanel={onClose}>
    <NotificationsModuleContent className={className} />
  </LocalNotificationsProvider>
);
