import { FC } from "react";
import TextField, { TextFieldPropsI } from "../text-field";
import { clsxMerge } from "shared/lib/helpers";

const EnhancedTextField = TextField();

interface TextFieldWithIndicatorI extends TextFieldPropsI {
  prefixComponent?: any;
  suffixComponent?: any;
  groupContainerClassName?: string;
}

export const TextFieldWithIndicator: FC<TextFieldWithIndicatorI> = (props) => {
  return (
    <div
      className={clsxMerge(
        "flex items-center ",
        "rounded-lg border-2 border-black/10",
        props.groupContainerClassName
      )}
    >
      {props.prefixComponent}

      <EnhancedTextField
        className="mb-0"
        inputClassName="bg-inherit border-none focus:outline-none focus:bg-inherit"
        {...props}
      />

      {props.suffixComponent}
    </div>
  );
};

export default TextFieldWithIndicator;
