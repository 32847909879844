import { FC } from "react";

import { clsxMerge } from "shared/lib/helpers";

interface HistoryCardCallNotesPropsI {
  className?: string;
  notes?: string;
  isCustomerNotes?: boolean;
}

export const HistoryCardCallNotes: FC<HistoryCardCallNotesPropsI> = ({
  className,
  notes,
  isCustomerNotes = false,
}) => (
  <div className={clsxMerge("flex w-full flex-col gap-3", className)}>
    <h6 className="brand-typography-h6 @xl:block hidden text-black">
      {isCustomerNotes ? "AE" : "Caller"} notes
    </h6>

    <div
      className={clsxMerge(
        "brand-typography-body6 break-anywhere flex w-full leading-6",
        "rounded-lg border bg-[#F4F4F4]",
        notes
          ? "min-h-[60px] whitespace-pre-line p-3 text-black/80"
          : "items-center justify-center py-[60px] text-black/40"
      )}
    >
      {notes || "No notes attached"}
    </div>
  </div>
);
