import Skeleton from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";

const NotificationSkeleton = () => (
  <div className="flex w-full p-6">
    <div className="mr-4">
      <div>
        <Skeleton circle width={"40px"} height={"40px"} />
      </div>
    </div>
    <div className="h-full w-full">
      <div className="w-[80%]">
        <Skeleton count={1} containerClassName="flex-1 " />
      </div>
      <div className="w-[70%]">
        <Skeleton count={1} containerClassName="flex-1" />
      </div>
    </div>
  </div>
);

const notifications = new Array(2).fill(0);

export const LoadingSkeleton = () => (
  <div className="w-full animate-fadein">
    {notifications.map((_n, i) => (
      <NotificationSkeleton key={i} />
    ))}
  </div>
);
