import { FC, useMemo } from "react";

import { Tabs, createTab } from "shared/ui/tabs";
import { DefaultPill } from "shared/ui/pills/default-pill";
import { clsxMerge } from "shared/lib/helpers";
import { NotificationsTabI } from "./interfaces";

interface NotificationsPanelTabsI {
  unreadCounter?: number;
  onChange?: (id: NotificationsTabI) => void;
}

export const NOTIFICATIONS_TABS = {
  ALL: "all",
  UNREAD: "unread",
} as const;

export const NotificationsPanelTabs: FC<NotificationsPanelTabsI> = ({
  unreadCounter: unread,
  onChange,
}) => {
  const tabs = useMemo(
    () => [
      createTab(NOTIFICATIONS_TABS.ALL, <span>All</span>),

      createTab(
        NOTIFICATIONS_TABS.UNREAD,
        <span className="flex  items-center gap-x-1">
          <span>Unread</span>
          {!!unread && (
            <DefaultPill
              className={clsxMerge("min-w-[10px] scale-75", {
                "px-2": unread < 10,
                "px-1": unread >= 10,
              })}
            >
              {unread}
            </DefaultPill>
          )}
        </span>
      ),
    ],
    [unread]
  );

  return (
    <Tabs
      items={tabs}
      selected={NOTIFICATIONS_TABS.ALL}
      isSoftRouting
      onChange={(id?: string) => onChange?.(id as NotificationsTabI)}
    />
  );
};
