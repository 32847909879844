/**
 * Gets an asset from Glencoco's S3 "glencoco-assets" bucket.
 *
 * @param {string} assetFileName
 */
export const getS3AssetPath = (assetFileName: string) =>
  `https://glencoco-assets.s3.amazonaws.com/${assetFileName}`;

/**
 * Gets an audio file from Glencoco's S3 "glencoco-assets" bucket "audio" folder.
 * @param audioFileName
 */
export const getS3AudioPath = (audioFileName: string) =>
  getS3AssetPath(`audio/${audioFileName}`);
