import { FC, useEffect, useMemo, useState } from "react";
import { createRoot } from "react-dom/client";
import DomPurifier from "dompurify";
import ReactPlayer from "react-player";

import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { clsxMerge } from "shared/lib/helpers/styles";
import { DOM_PURIFIER_CONFIG } from "./constants";
import * as htmlProcessors from "./utils";

interface RenderHTMLPropsI extends PropsWithClassNameI {
  html: string;
}

const videoInitializer = () => {
  Array.from(document.querySelectorAll(".video-container"))?.forEach(
    (videoContainer) => {
      const contentURL = videoContainer.getAttribute("data-video-url");

      if (contentURL) {
        createRoot(videoContainer).render(
          <div className="flex w-full justify-center">
            <ReactPlayer
              url={contentURL}
              controls
              className="mt-8 h-[500px] w-full rounded-xl"
            />
          </div>
        );
      }
    }
  );
};

const RenderHTML: FC<RenderHTMLPropsI> = ({ className, html }) => {
  const [isRendererReady, setIsRendererReady] = useState(false);

  useEffect(() => {
    (DomPurifier.addHook as any)?.(
      "afterSanitizeAttributes",
      (element: Element) => {
        htmlProcessors.processLink(element);
        htmlProcessors.processVideoEmbed(element);
      }
    );

    setIsRendererReady(true);

    return () => {
      DomPurifier.removeHook("afterSanitizeAttributes");

      setIsRendererReady(false);
    };
  }, [html]);

  const purifiedHTML = useMemo(
    () =>
      isRendererReady ? DomPurifier.sanitize(html, DOM_PURIFIER_CONFIG) : null,
    [isRendererReady]
  );

  useEffect(() => {
    if (purifiedHTML) {
      videoInitializer();
    }
  }, [purifiedHTML]);

  return (
    <div
      className={clsxMerge("rendered-html", className)}
      dangerouslySetInnerHTML={{
        __html: purifiedHTML || "Loading content...",
      }}
    />
  );
};

export default RenderHTML;
