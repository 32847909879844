import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
  PropsWithChildren,
  FC,
} from "react";

import {
  useFetchNotificationsQuery,
  UseFetchNotificationsQuery,
} from "@/modules/notifications/queries";
import { NotificationsTabI } from "shared/ui/notifications/panel/interfaces";
import { NOTIFICATIONS_TABS } from "shared/ui/notifications/panel/tabs";
import {
  useReadAllNotificationsMutation,
  useReadNotificationMutation,
} from "@/modules/notifications/queries/mutations";

interface LocalNotificationsProviderPropsI extends PropsWithChildren {
  onClosePanel?: () => void;
}

export interface LocalNotificationsContextI {
  notificationsApi: UseFetchNotificationsQuery;

  closePanel?: () => void;

  selectedSection: NotificationsTabI;
  setSelectedSection: Dispatch<SetStateAction<NotificationsTabI>>;

  readNotification: (id: string) => Promise<any>;
  readAllNotifications: () => Promise<any>;
}

const LocalNotificationsContext = createContext<LocalNotificationsContextI>({
  notificationsApi: {} as UseFetchNotificationsQuery,
  selectedSection: NOTIFICATIONS_TABS.ALL,
  setSelectedSection: () => {},
  readNotification: () => Promise.resolve(),
  readAllNotifications: () => Promise.resolve(),
});

/**
 *
 * @param onClosePanel - External function that should act as close panel mechanism.
 * Internally panel doesn't control it's open or closed state
 */
export const LocalNotificationsProvider: FC<
  LocalNotificationsProviderPropsI
> = ({ onClosePanel: closePanel, children }) => {
  const [selectedSection, setSelectedSection] = useState<NotificationsTabI>(
    NOTIFICATIONS_TABS.ALL
  );

  const notificationsApi = useFetchNotificationsQuery(selectedSection);

  const { mutateAsync: readNotification } = useReadNotificationMutation();
  const { mutateAsync: readAllNotifications } =
    useReadAllNotificationsMutation();

  return (
    <LocalNotificationsContext.Provider
      value={{
        selectedSection,
        setSelectedSection,
        notificationsApi,
        closePanel,
        readNotification,
        readAllNotifications,
      }}
    >
      {children}
    </LocalNotificationsContext.Provider>
  );
};

export const useLocalNotificationsContext = (): LocalNotificationsContextI =>
  useContext(LocalNotificationsContext);
