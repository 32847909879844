import { StreamAPIConfig } from "@/api/stream";

export interface GenerateEmailStreamRequestParamsI {
  email_id: string;
  campaign_id: string;
  contact_email: string;
  account_id: string;
  contact_id: string;
  account_name: string;
  account_industry: string;
  account_city: string;
  account_state: string;
  account_headcount: string;
  account_website: string;
  email_signature: string;
  user_prompt: string;
}

export interface EmailWebAIAPII {
  streamEmailSubject: (
    params: GenerateEmailStreamRequestParamsI
  ) => Promise<Response>;

  streamEmailBody: (
    params: GenerateEmailStreamRequestParamsI
  ) => Promise<Response>;
}

function fetchWithTimeout(url: string, options: any, timeout = 30000) {
  const controller = new AbortController();
  const { signal } = controller;
  const fetchOptions = { ...options, signal };

  const timeoutId = setTimeout(() => controller.abort(), timeout);

  return fetch(url, fetchOptions).finally(() => clearTimeout(timeoutId));
}

export const EmailWebAIAPIEndpoints = (API: StreamAPIConfig) => ({
  streamEmailSubject: async (parameters: GenerateEmailStreamRequestParamsI) => {
    const response = await fetchWithTimeout(
      `${API.baseURL}/email/generate_content_subject`,
      {
        method: "POST",
        headers: API.headers,
        body: JSON.stringify(parameters),
      },
      API.timeout
    );

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error ${response.status}: ${errorText}`);
    }

    return response;
  },
  streamEmailBody: async (parameters: GenerateEmailStreamRequestParamsI) => {
    const response = await fetchWithTimeout(
      `${API.baseURL}/email/generate_content_body`,
      {
        method: "POST",
        headers: API.headers,
        body: JSON.stringify(parameters),
      },
      API.timeout
    );

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error ${response.status}: ${errorText}`);
    }

    return response;
  },
  // ... other endpoints
});
