import { useInfiniteQuery } from "@tanstack/react-query";

import { NotificationsTabI } from "shared/ui/notifications/panel/interfaces";
import { useApiClient } from "@/context/api-client";
import { LocalNotificationsQueryKeys } from "@/modules/notifications/queries/keys";
import { NotificationI } from "@/interfaces/notification";
import { NOTIFICATIONS_TABS } from "shared/ui/notifications/panel/tabs";

export const selectUnreadNotifications = (notifications: NotificationI[]) =>
  notifications.filter((n) => !n.is_seen) || [];

export const useFetchNotificationsQuery = (section: NotificationsTabI) => {
  const apiClient = useApiClient();

  return useInfiniteQuery({
    queryKey: LocalNotificationsQueryKeys.notifications(section),
    queryFn: ({ pageParam }) =>
      apiClient.notifications.getNotifications({
        nextToken: pageParam,
        section,
      }),
    initialPageParam: undefined as null | string | undefined,
    getNextPageParam: (lastPage) => lastPage.data?.next_token || undefined,
    select: (data) => {
      const notifications =
        (data?.pages
          .flatMap((page) => page.data?.notifications)
          .filter(Boolean) as NotificationI[]) || [];

      return section === NOTIFICATIONS_TABS.UNREAD
        ? selectUnreadNotifications(notifications)
        : notifications;
    },
  });
};

export type UseFetchNotificationsQuery = ReturnType<
  typeof useFetchNotificationsQuery
>;
