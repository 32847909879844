import { AxiosInstance } from "axios";
import {
  GetEmailInboxCountersResponseI,
  GetEmailInboxRequestI,
  GetEmailInboxResponseI,
  GetEmailThreadResponseI,
} from "shared/lib/interfaces/email-inbox";

export interface EmailTemplateI {
  id?: string;
  name?: string;
  is_custom?: boolean;
  prompt?: string;
  attach_one_pager?: boolean;
  attachments?: AttachmentI[];
}

export interface AttachmentI {
  id: string;
  name: string;
}

export interface GetEmailTemplatesListResponseI {
  email_templates: EmailTemplateI[];
}

export interface CrateEmailTemplateResponseI {
  email_template: EmailTemplateI[];
}

export interface CreateEmailTemplateRequestParamsI {
  name: string;
  prompt: string;
  attach_one_pager: boolean;
}

export interface CreateEmailTemplateResponseI {
  email_template: EmailTemplateI;
}

export interface CheckCampaignEmailSetupResponseI {
  is_email_setup: boolean;
}

export interface UpdateEmailTemplateRequestParamsI
  extends CreateEmailTemplateRequestParamsI {}

export interface CreateEmailDraftRequestParamsI {
  campaign_id: string;
  account_id: string;
  contact_id: string;
}

export interface CreateEmailDraftResponseI {
  email_id?: string;
  from_email?: string;
}

export interface GenerateAIEmailRequestParamsI {
  email_id: string;
}

export interface GenerateEmailExampleRequestParamsI {
  prompt: string;
  campaign_id: string;
  account_id: string;
  contact_id: string;
}

export interface GenerateEmailExampleResponseI {
  email_id: string;
  campaign_id: string;
  contact_email: string;
  account_id: string;
  contact_id: string;
  account_name: string;
  account_industry: string;
  account_city: string;
  account_state: string;
  account_headcount: string;
  account_website: string;
  email_signature: string;
  user_prompt: string;
}

export interface GetEmailGenerationStatusResponseI {
  is_complete?: boolean;
  created_at?: string;
  subject?: string;
  body?: string;
  attachments?: AttachmentI[];
}

export interface ValidateEmailsRequestParamsI {
  emails: string[];
}

export interface ValidateEmailsResponseI {
  valid_emails: string[];
  invalid_emails: string[];
}

export interface SendEmailWithAIRequestParamsI {
  to: string[];
  cc?: string[];
  bcc?: string[];
  max_follow_ups: number;
  follow_up_interval_days: number;
}

export interface SendEmailRequestParamsI extends SendEmailWithAIRequestParamsI {
  subject: string;
  body: string; //plain_text
  raw_body: string; //html_text
  attachments: string[];
}

export const EmailAPIEndpoints = (api: AxiosInstance) => ({
  getEmailTemplatesList: () =>
    api.get<GetEmailTemplatesListResponseI>(`/v1/email_template/list`),

  checkCampaignHasEmailAutomation: (campaignId: string) =>
    api.get<CheckCampaignEmailSetupResponseI>(
      `/v2/campaign/${campaignId}/email_is_setup`
    ),

  createEmailTemplate: (params: CreateEmailTemplateRequestParamsI) =>
    api.post<CreateEmailTemplateResponseI>(`/v1/email_template`, params),

  updateEmailTemplate: (
    templateId: string,
    params: UpdateEmailTemplateRequestParamsI
  ) => api.patch(`/v1/email_template/${templateId}`, params),

  deleteEmailTemplate: (templateId: string) =>
    api.delete(`/v1/email_template/${templateId}`),

  createEmailDraft: (params: CreateEmailDraftRequestParamsI) =>
    api.post<CreateEmailDraftResponseI>("/v2/email/create_draft", params),

  generateEmailExample: (params: GenerateEmailExampleRequestParamsI) =>
    api.post<GenerateEmailExampleResponseI>(
      `/v2/email/generate_example`,
      params
    ),

  generateAIEmail: (
    templateId: string,
    params: GenerateAIEmailRequestParamsI
  ) =>
    api.post<GenerateEmailExampleResponseI>(
      `/v1/email_template/${templateId}/generate`,
      params
    ),

  getEmailGenerationStatus: (emailId: string) =>
    api.get<GetEmailGenerationStatusResponseI>(
      `/v1/email_template/${emailId}/generation_status`
    ),

  validateEmails: (params: ValidateEmailsRequestParamsI) =>
    api.post<ValidateEmailsResponseI>(
      `/v1/email/confirm_email_address`,
      params
    ),

  sendEmail: (emailId: string, params: SendEmailRequestParamsI) =>
    api.post(`/v1/email/${emailId}/send`, params),

  sendEmailWithAI: (emailId: string, params: SendEmailWithAIRequestParamsI) =>
    api.post(`/v1/email/${emailId}/send_without_waiting`, params),

  getInbox: ({
    inbox_type,
    campaign_ids,
    search_term,
    next_token,
  }: GetEmailInboxRequestI) =>
    api.post<GetEmailInboxResponseI>("/v1/inbox", {
      inbox_type,
      campaign_ids,
      next_token,
      search_term: search_term || undefined,
    }),

  getInboxThread: (threadId: string, nextToken?: string | null) =>
    api.post<GetEmailThreadResponseI>(`/v1/inbox/thread/${threadId}`, {
      next_token: nextToken,
    }),

  getInboxCounters: (campaignIds: string[]) =>
    api.post<GetEmailInboxCountersResponseI>("/v1/inbox_stats", {
      campaign_ids: campaignIds,
    }),
});
