import {
  CalendarIcon,
  EnvelopeOpenIcon,
  PhoneArrowDownLeftIcon,
  PhoneXMarkIcon,
  StarIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";

import { SYSTEM_LIST_TYPES } from "@/modules/pipeline/lists/list/constants";

// Fixed, pre-defined icons only for lists generated/maintained by the system
export const LISTS_ICON_MAP = {
  [SYSTEM_LIST_TYPES.BOOKED_MEETINGS]: <CalendarIcon className="w-4" />,
  [SYSTEM_LIST_TYPES.CALLBACKS]: <PhoneArrowDownLeftIcon className="w-4" />,
  [SYSTEM_LIST_TYPES.EMAIL_ACTIONS]: <EnvelopeOpenIcon className="w-4" />,
  [SYSTEM_LIST_TYPES.FEEDBACK_RECEIVED]: <StarIcon className="w-4" />,
  [SYSTEM_LIST_TYPES.MISSED_CALLS]: <PhoneXMarkIcon className="w-4" />,
  [SYSTEM_LIST_TYPES.NO_SOWS_AND_CANCELS]: <XCircleIcon className="w-4" />,
};
