import {
  ArrowLeftStartOnRectangleIcon,
  BanknotesIcon,
  ChartBarIcon,
  ChatBubbleLeftRightIcon,
  CurrencyDollarIcon,
  EnvelopeIcon,
  GlobeAltIcon,
  PhoneArrowUpRightIcon,
  RocketLaunchIcon,
  ShareIcon,
  TrophyIcon,
  UserIcon,
  PresentationChartLineIcon,
  InboxStackIcon,
} from "@heroicons/react/24/outline";
import { HomeIcon, PhoneIcon, QueueListIcon } from "@heroicons/react/24/solid";

import {
  AnnouncementSpeakerIcon,
  ChatBubbleEllipsisIcon,
  CoachIcon,
  CogsIcon,
  LeaderboardIcon,
  MoneyFunnelIcon,
  UserMicrophoneIcon,
} from "shared/ui/icons";

import { NOTIFICATION_MAPPED_IDS } from "@/components/primary-navigation/shared/notification-label";
import { PrimaryNavigationItemCollapsableWithIconI } from "@/components/primary-navigation/v3/interfaces";
import Router from "@/helpers/router";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";

//TODO update pipeline page urls
export const MY_CAMPAIGNS_ITEM_ID = "my_campaigns";
export const PIPELINE_NAVIGATION_ITEM_ID = "accounts";
export const REFERRALS_ITEM_ID = "referrals";
export const SETTINGS_ITEM_ID = "settings";

export const SALES_FLOORS_ITEM_ID = "sales_floors";
export const SALES_FLOOR_ITEM_ID = "sales_floor";
export const CAMPAIGN_SALES_FLOOR_ITEM_ID = "campaign_sales_floor";

export const NAV_GROUP_NAMES = {
  HOME: "home",
  LEADS: "leads",
  CAMPAIGNS: "campaigns",
  PERFORMANCE: "performance",
  MESSAGES: "messages",
  SETTINGS: "settings",
  ONBOARDING: "onboarding",
  SALES_FLOOR: "sales_floor",
} as const;

type NavGroupNamesI = ValueOfObjectFields<typeof NAV_GROUP_NAMES>;

// All nav items organized by their group structures.
// Remember to reference the item you want from here into the appropriate menu array (found further below).
export const NAV_V3_ITEMS: Record<
  NavGroupNamesI,
  PrimaryNavigationItemCollapsableWithIconI
> = {
  [NAV_GROUP_NAMES.HOME]: {
    text: "Home",
    icon: <HomeIcon />,
    href: Router.dashboard(),
    disabled: false,
  },
  [NAV_GROUP_NAMES.LEADS]: {
    text: "Leads",
    id: PIPELINE_NAVIGATION_ITEM_ID,
    isCollapsed: true,
    icon: <CurrencyDollarIcon />,
    children: [
      {
        text: "Working lists",
        href: Router.lists(),
        icon: <QueueListIcon />,
      },
      {
        text: "Open pipeline",
        href: Router.accounts(),
        icon: <MoneyFunnelIcon />,
      },
      {
        text: "History",
        href: Router.callHistory(),
        icon: <PhoneIcon />,
      },
    ],
  },
  [NAV_GROUP_NAMES.CAMPAIGNS]: {
    text: "Campaigns",
    isCollapsed: false,
    icon: <AnnouncementSpeakerIcon />,
    children: [
      {
        text: "My campaigns",
        id: MY_CAMPAIGNS_ITEM_ID,
        href: Router.myCampaigns(),
        icon: <UserMicrophoneIcon />,
      },
      {
        text: "Discover campaigns",
        href: Router.marketplace(),
        icon: <RocketLaunchIcon />,
      },
    ],
  },
  [NAV_GROUP_NAMES.PERFORMANCE]: {
    text: "Performance",
    icon: <LeaderboardIcon />,
    tooltip:
      "Check out the best-performing callers. Could your name be among the distinguished few?",
    isCollapsed: true,
    disabled: false,
    children: [
      {
        text: "Dashboard",
        tooltip: "View your achievements. Take a moment to let it all in.",
        href: Router.performanceDashboard(),
        icon: <PresentationChartLineIcon />,
      },
      {
        text: "Leaderboards",
        tooltip: "View your achievements. Take a moment to let it all in.",
        href: Router.leaderboard(),
        icon: <ChartBarIcon />,
      },
      {
        text: "Trophies",
        tooltip: "View your achievements. Take a moment to let it all in.",
        href: Router.trophies(),
        icon: <TrophyIcon />,
      },
    ],
  },
  [NAV_GROUP_NAMES.SALES_FLOOR]: {
    id: SALES_FLOORS_ITEM_ID,
    text: "Sales floors",
    isCollapsed: false,
    icon: <GlobeAltIcon className="w-[26px] text-white" />,
    children: [
      {
        id: SALES_FLOOR_ITEM_ID,
        text: "Community sales floor",
        href: Router.salesFloor(),
        icon: <GlobeAltIcon />,
      },
      {
        id: CAMPAIGN_SALES_FLOOR_ITEM_ID,
        text: "Campaign sales floors",
        href: Router.mySalesFloors(),
        icon: <ChatBubbleLeftRightIcon />,
      },
    ],
  },
  [NAV_GROUP_NAMES.MESSAGES]: {
    id: NOTIFICATION_MAPPED_IDS.TOTAL_MESSAGES,
    text: "Messages",
    icon: <ChatBubbleEllipsisIcon />,
    isCollapsed: true,
    disabled: false,
    children: [
      {
        text: "Coaching",
        id: NOTIFICATION_MAPPED_IDS.COACHING,
        href: Router.coaching(),
        icon: <CoachIcon />,
      },
      {
        text: "Account messages",
        id: NOTIFICATION_MAPPED_IDS.MESSAGES,
        href: Router.messages(),
        icon: <EnvelopeIcon />,
      },
      {
        text: "Email Inbox",
        id: NOTIFICATION_MAPPED_IDS.EMAILS,
        href: Router.emailInbox(),
        icon: <InboxStackIcon />,
      },
    ],
  },
  [NAV_GROUP_NAMES.SETTINGS]: {
    text: "Settings",
    id: SETTINGS_ITEM_ID,
    icon: <CogsIcon className="w-5" />,
    isCollapsed: true,
    children: [
      {
        text: "My Profile",
        href: Router.settingsProfile(),
        icon: <UserIcon />,
      },
      {
        text: "Call Forwarding",
        href: Router.settingsCallForwarding(),
        icon: <PhoneArrowUpRightIcon />,
      },
      {
        text: "Payouts",
        href: Router.settingsPayouts(),
        icon: <BanknotesIcon />,
      },
      {
        text: "Referrals",
        id: REFERRALS_ITEM_ID,
        icon: <ShareIcon />,
        href: Router.settingsReferrals(),
      },
      {
        text: "Sign out",
        href: Router.logout(),
        icon: <ArrowLeftStartOnRectangleIcon />,
      },
    ],
  },
  [NAV_GROUP_NAMES.ONBOARDING]: {
    text: "Settings",
    id: SETTINGS_ITEM_ID,
    icon: <CogsIcon className="w-5 text-white" />,
    isCollapsed: true,
    children: [
      {
        text: "Sign out",
        href: Router.logout(),
        icon: <ArrowLeftStartOnRectangleIcon />,
      },
    ],
  },
};

// These nav items persist across all screen sizes

// These nav items hide on collapsed screen sizes
export const NAV_V3_ITEMS_PRIMARY: Array<PrimaryNavigationItemCollapsableWithIconI> =
  [
    NAV_V3_ITEMS[NAV_GROUP_NAMES.CAMPAIGNS],
    NAV_V3_ITEMS[NAV_GROUP_NAMES.PERFORMANCE],
  ];
