import { FC } from "react";

import { AttachmentI } from "@/api/routes/email";
import { clsxMerge } from "shared/lib/helpers";
import { CloseIcon } from "shared/ui/icons";
import { DefaultPill } from "shared/ui/pills";
import { ActionMenu } from "shared/ui/action-menu";

interface EmailAttachmentsSectionPropsI {
  className?: string;
  attachmentOptions?: AttachmentI[];
  selectedAttachments?: AttachmentI[];
  onChange?: (attachment: AttachmentI) => void;
}

export const EmailAttachmentsSection: FC<EmailAttachmentsSectionPropsI> = ({
  className,
  attachmentOptions = [],
  selectedAttachments = [],
  onChange = () => {},
}) => {
  if (!attachmentOptions || attachmentOptions.length === 0) {
    return null;
  }

  return (
    <section>
      <div
        className={clsxMerge("flex items-center justify-between", className)}
      >
        <div className="typography-body-3-medium">Attachments</div>

        <ActionMenu
          className="shadow-2xl"
          trigger={
            <button
              type="button"
              className={clsxMerge(
                "ae-link cursor-pointer typography-body-4-medium",
                !attachmentOptions?.length &&
                  "cursor-not-allowed text-black opacity-30"
              )}
            >
              + Add attachment
            </button>
          }
          actions={attachmentOptions?.map((a) => ({
            title: a.name,
            handler: () => onChange(a),
          }))}
        />
      </div>

      <div
        className={clsxMerge(
          "mt-2 flex flex-wrap items-center gap-2 rounded-lg border px-4 py-3.5",
          "border-[#ccc] bg-white"
        )}
      >
        {!selectedAttachments?.length && (
          <span className="text-[#ccc] typography-body-4-medium">
            No attachments
          </span>
        )}

        {selectedAttachments.map((attachment, idx) => (
          <DefaultPill
            key={idx}
            className="border border-[#ccc] bg-white py-3 text-black typography-body-5-medium"
          >
            {attachmentOptions.find((opt) => opt.id === attachment.id)?.name}

            <button onClick={() => onChange(attachment)}>
              <CloseIcon className="ml-2 w-3" />
            </button>
          </DefaultPill>
        ))}
      </div>
    </section>
  );
};
