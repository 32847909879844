import { FC, PropsWithChildren } from "react";

import { InfiniteScrollWithObserverCommonPropsI } from "./interfaces";
import InfiniteScrollWithObserver from "./index";

interface InfiniteScrollWithQueryPropsI
  extends PropsWithChildren,
    InfiniteScrollWithObserverCommonPropsI {
  api: {
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    refetch?: () => void;
    isEmpty: boolean;
    hasNextPage: boolean;
    fetchNextPage: () => void;
  };
}

export const InfiniteScrollWithQuery: FC<InfiniteScrollWithQueryPropsI> = ({
  children,
  api,
  ...rest
}) => (
  <InfiniteScrollWithObserver
    isMore={api.hasNextPage}
    isLoading={api.isFetching}
    hasError={api.isError}
    onMore={api.fetchNextPage}
    onReload={api.refetch}
    {...rest}
  >
    {children}
  </InfiniteScrollWithObserver>
);
